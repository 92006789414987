import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

// Connects to data-controller="product"
export default class extends Controller {
  static targets = ['form', 'requestUrlField', 'price', 'productInstanceJson', 'addToCartButton'];
  static values = { fieldsToClearOnSizeChange: Array, metaPixelConversionData: Object };

  // Update window history whenever request_url field is connected.
  requestUrlFieldTargetConnected(element) {
    if (!element.value) return;
    window.history.pushState(window.history.state, '', element.value);
  }

  submitForm(formData) {
    // Clean up any empty values.
    for (const [key, value] of formData.entries()) {
      if (key == 'custom_text') continue;
      if (!value) formData.delete(key);
    }
    const asString = new URLSearchParams(formData).toString();
    get(this.formTarget.action + '?' + asString, { responseKind: 'turbo-stream' });
  }

  updateForm(event) {
    if (event.target.name == 'orientation') return this.selectOrientation(event);
    const formData = new FormData(this.formTarget);
    if (event.target.name == 'size') {
      this.fieldsToClearOnSizeChangeValue.forEach((field) => {
        formData.delete(field);
      });
    }
    this.submitForm(formData);
  }

  // When image is changed, we need to update the everything.
  selectImage(event) {
    const formData = new FormData(this.formTarget);
    formData.set('image_id', event.target.value);
    formData.set('filter', event.target.dataset.filter);
    formData.set('update_all', true);
    formData.delete('geometry');
    this.submitForm(formData);
  }

  updatePrice(event) {
    this.priceTarget.innerHTML = event.params.price;
    this.addToCartButtonTarget.dataset.conversionsValueParam =
      event.params.price.replace(/[^\d.]/g, '');
  }

  // If orientation changes, we'll need to reset the geometry otherwise image may not fit.
  selectOrientation() {
    const formData = new FormData(this.formTarget);
    formData.delete('geometry');
    this.submitForm(formData);
  }

  updatePrintfileGeometry(event) {
    const formData = new FormData(this.formTarget);
    formData.set('geometry', event.detail);
    this.submitForm(formData);
  }

  // Block propagation of event so form isn't updated due to text change.
  customTextChanged(event) {
    event.stopPropagation();
  }
}
